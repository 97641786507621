<template>
  <div class="layout-menu-wrapper">
    <div class="aside">
      <el-aside :width="isCollapse ? '64px': '208px'">
        <el-menu
            router
            :default-active="activePath"
            :collapse="isCollapse"
            :collapse-transition="false">
          <el-menu-item index="/dashboard">
            <i class="fa-solid fa-gauge layout-menu-icon"></i>
            <span slot="title">工作台</span>
          </el-menu-item>
          <template v-for="menu in menus">
            <el-submenu :index="menu.id" :key="menu.id" v-if="menu.child.length > 0">
              <template slot="title">
                <div class="title-row">
                  <div class="menu-icon">
                    <i :class="menu.icon" class="layout-menu-icon"></i>
                  </div>
                  <div class="menu-title">
                    <span>{{ menu.title }}</span>
                  </div>
                </div>
              </template>
              <el-menu-item class="el-menu-item-sub" :key="item.id" v-for="item in menu.child" :index="item.path">
                {{ item.title }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :key="menu.id" :index="menu.path">
              <i :class="menu.icon" class="layout-menu-icon"></i>
              <span slot="title">{{ menu.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
    </div>
    <div class="collapse-wrapper">
      <i :class="{'el-icon-s-unfold': isCollapse, 'el-icon-s-fold': !isCollapse}" @click="handleMenuSwitch"></i>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AppMenu',
  computed: {
    menus () {
      return this.$store.getters.menus || []
    },
    activePath () {
      return this.$route.path
    },
    isCollapse () {
      return this.$store.getters.menuIsCollapse
    }
  },
  data () {
    return {}
  },
  methods: {
    handleMenuSwitch () {
      this.$store.dispatch('toggleMenuIsCollapse')
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.layout-menu-wrapper {
  display: flex;
  flex-direction: column;

  .aside {
    flex: 1;
    background-color: #fff;
    overflow: hidden auto;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);

    .el-aside {
      overflow: hidden;
      box-shadow: none;
    }
  }

  .collapse-wrapper {
    background-color: #fff;
    //box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    border-top: 1px solid rgba(0, 0, 0, .06);
    box-sizing: border-box;

    i {
      cursor: pointer;
    }
  }
}
</style>
