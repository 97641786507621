import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login')
    },
    {
      path: '/refresh',
      name: 'Refresh',
      component: () => import('@/views/refresh')
    },
    // {
    //   path: '/redirect/:path*',
    //   hidden: true,
    //   component: () => import('@/views/redirect/index')
    // },
    {
      path: '/',
      name: 'Main',
      redirect: '/dashboard',
      component: Layout,
      children: [
        {
          path: '/dashboard',
          name: 'Home',
          component: () => import('@/views/dashboard/dashboard'),
          meta: {
            title: '工作台',
            affix: true
          }
        },
        {
          path: '/doc',
          name: 'DocRemark',
          component: () => import('@/views/doc/doc-remark')
        }
      ]
    },
    {
      path: '/sys',
      component: Layout,
      children: [
        {
          path: '/sys/user',
          name: 'User',
          component: () => import('@/views/sys/user'),
          meta: { title: '员工管理' }
        },
        {
          path: '/sys/role',
          name: 'Role',
          component: () => import('@/views/sys/role'),
          meta: { title: '角色管理' }
        },
        {
          path: '/sys/role/resource',
          name: 'RoleResource',
          component: () => import('@/views/sys/role-resource'),
          meta: { title: '权限设置' }
        },
        {
          path: '/sys/dept',
          name: 'Dept',
          component: () => import('@/views/sys/dept'),
          meta: { title: '部门管理' }
        },
        {
          path: '/sys/user/password/modify',
          name: 'UserPasswordModify',
          component: () => import('@/views/sys/user-password-modify'),
          meta: { title: '修改密码' }
        }
      ]
    },
    {
      path: '/cms',
      component: Layout,
      children: [
        {
          path: '/cms/article',
          name: 'Article',
          component: () => import('@/views/cms/article'),
          meta: { title: '文章管理' }
        },
        {
          path: '/cms/article/edit',
          name: 'ArticleEdit',
          component: () => import('@/views/cms/article-edit'),
          meta: {
            title: '文章发布',
            needRefresh: true
          }
        },
        {
          path: '/cms/video',
          name: 'Video',
          component: () => import('@/views/cms/video'),
          meta: { title: '视频管理' }
        },
        {
          path: '/cms/video/edit',
          name: 'VideoEdit',
          component: () => import('@/views/cms/video-edit'),
          meta: {
            title: '视频发布',
            needRefresh: true
          }
        },
        {
          path: '/cms/poster',
          name: 'Poster',
          component: () => import('@/views/cms/poster'),
          meta: { title: '海报管理' }
        },
        {
          path: '/cms/poster/edit',
          name: 'PosterEdit',
          component: () => import('@/views/cms/poster-edit'),
          meta: {
            title: '海报发布',
            needRefresh: true
          }
        }
      ]
    },
    {
      path: '/user',
      component: Layout,
      children: [
        {
          path: '/user/list',
          name: 'PlatformUser',
          component: () => import('@/views/user/user-list'),
          meta: { title: '用户列表' }
        },
        {
          path: '/user/edit',
          name: 'UserEdit',
          component: () => import('@/views/user/user-edit'),
          meta: { title: '用户编辑' }
        },
        {
          path: '/user/migrate/relation',
          name: 'UserMigrateRelation',
          component: () => import('@/views/user/user-migrate-relation'),
          meta: { title: '绑定关系转移' }
        },
        {
          path: '/user/material/video/tpl',
          name: 'UserMaterialVideoTpl',
          component: () => import('@/views/user/video-tpl'),
          meta: { title: '视频模板' }
        },
        {
          path: '/user/material/video/tpl/edit',
          name: 'UserMaterialVideoTplEdit',
          component: () => import('@/views/user/video-tpl-edit'),
          meta: { title: '视频模板编辑' }
        },
        {
          path: '/user/migrate',
          name: 'UserMigrate',
          component: () => import('@/views/user/user-migrate'),
          meta: { title: '账号转移' }
        },
        {
          path: '/user/migrate/log',
          name: 'UserMigrateLog',
          component: () => import('@/views/user/user-migrate-log'),
          meta: { title: '账号转移记录' }
        },
        {
          path: '/user/public/list',
          name: 'UserPublicList',
          component: () => import('@/views/user/user-public-list'),
          meta: { title: '客户公海' }
        }
      ]
    },
    {
      path: '/oms',
      component: Layout,
      children: [
        {
          path: '/oms/order',
          name: 'Order',
          component: () => import('@/views/oms/order'),
          meta: { title: '订单列表' }
        },
        {
          path: '/oms/invoice/header',
          name: 'InvoiceHeader',
          component: () => import('@/views/oms/invoice-header'),
          meta: { title: '发票抬头' }
        },
        {
          path: '/oms/invoice',
          name: 'Invoice',
          component: () => import('@/views/oms/invoice'),
          meta: { title: '发票管理' }
        },
        {
          path: '/oms/invoice/apply',
          name: 'InvoiceApply',
          component: () => import('@/views/oms/invoice-apply'),
          meta: { title: '申请开票' }
        },
        {
          path: '/oms/order/vip/create',
          name: 'OrderVipCreate',
          component: () => import('@/views/oms/order-vip-create'),
          meta: { title: '开通VIP' }
        },
        {
          path: '/oms/order/vip/create/result',
          name: 'OrderVipCreateResult',
          component: () => import('@/views/oms/order-vip-create-result'),
          meta: { title: '开通VIP结果' }
        }
      ]
    },
    {
      path: '/rpt',
      component: Layout,
      children: [
        {
          path: '/rpt/overview',
          name: 'Overview',
          component: () => import('@/views/rpt/overview'),
          meta: { title: '数据看板' }
        },
        {
          path: '/rpt/sales/rank',
          name: 'SalesRank',
          component: () => import('@/views/rpt/sales-rank'),
          meta: { title: '销售业绩' }
        }
      ]
    },
    {
      path: '/account',
      name: 'AccountIndex',
      component: () => import('@/views/account'),
      meta: { title: '我的帐户' },
      redirect: '/account/info',
      children: [
        {
          path: '/account/info',
          name: 'AccountInfo',
          component: () => import('@/views/account/account-info'),
          meta: { title: '帐户信息' }
        },
        {
          path: '/account/balance/log',
          name: 'BalanceLog',
          component: () => import('@/views/account/balance-log'),
          meta: { title: '余额明细' }
        },
        {
          path: '/account/vip/log',
          name: 'VipCardLog',
          component: () => import('@/views/account/vip-card-log'),
          meta: { title: 'VIP年卡明细' }
        },
        {
          path: '/account/bank/card',
          name: 'BankCard',
          component: () => import('@/views/account/bank-card'),
          meta: { title: '银行卡' }
        },
        {
          path: '/account/deal/password',
          name: 'DealPassword',
          component: () => import('@/views/account/deal-password'),
          meta: { title: '设置交易密码' }
        },
        {
          path: '/account/deal/password/reset',
          name: 'DealPasswordReset',
          component: () => import('@/views/account/deal-password-reset'),
          meta: { title: '重置交易密码' }
        },
        {
          path: '/account/recharge/vip/card',
          name: 'VipCardRecharge',
          component: () => import('@/views/account/vip-card-recharge'),
          meta: { title: 'VIP年卡充值' }
        },
        {
          path: '/account/recharge/balance',
          name: 'BalanceRecharge',
          component: () => import('@/views/account/balance-recharge'),
          meta: { title: '余额充值' }
        },
        {
          path: '/account/order/pay',
          name: 'OrderPay',
          component: () => import('@/views/account/order-pay'),
          meta: { title: '订单支付' }
        },
        {
          path: '/account/order/pay/success',
          name: 'OrderPayResult',
          component: () => import('@/views/account/order-pay-result'),
          meta: { title: '支付成功' }
        },
        {
          path: '/account/order',
          name: 'AccountOrder',
          component: () => import('@/views/account/order-list'),
          meta: { title: '我的订单' }
        },
        {
          path: '/account/withdraw/list',
          name: 'BalanceWithdraw',
          component: () => import('@/views/account/balance-withdraw'),
          meta: { title: '提现记录' }
        }
      ]
    },
    {
      path: '/tools',
      component: Layout,
      children: [
        {
          path: '/tools/douyin/lanv',
          name: 'DouyinLanv',
          component: () => import('@/views/tools/douyin-lanv'),
          meta: { title: '抖音蓝V' }
        }
      ]
    }
  ]
})
