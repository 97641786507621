import http from '@/utils/http'

// 获取公司关键词数据
export const getCompanyList = params => http.get('/dy/crawler/company/list/page', { params: params })

// 获取蓝V数据
export const getList = params => http.get('/dy/crawler/lanv/list', { params: params })

// 导入关键词数据
export const importKeywords = (data) => http.post('/dy/crawler/excel/import', data, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const removeLanv = data => http.post('/dy/crawler/del/lanv', data)

export const updateLabel = data => http.post('/dy/crawler/update/lanv/label', data)
