import Vue from 'vue'
import { LogApi } from '@/api'

Vue.config.errorHandler = function (err, vm, info, a) {
  console.log(Vue)
  // Don't ask me why I use Vue.nextTick, it just a hack.
  // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
  Vue.nextTick(() => {
    console.error(err)
    LogApi.save({
      project: 'skt-ams',
      endType: 1,
      url: window.location.href,
      errorMessage: err.stack
    }).catch()
  })
}
