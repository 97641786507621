<template>
  <el-dialog
      title="上传进度"
      :visible.sync="show"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="20%">
    <div class="upload-progress-body">
      <div>
        <el-progress type="circle" :percentage="percentage"></el-progress>
      </div>
      <div class="text">{{ formatText }}</div>
      <el-button class="btn-cancel" type="danger" @click="handleCancelUpload">取消上传</el-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  name: 'UploadProgress',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 0
    },
    formatText: String
  },
  methods: {
    handleCancelUpload () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>
.upload-progress-body {
  text-align: center;
}

.btn-cancel {
  margin-top: 20px;
}

.text {
  margin-top: 10px;
}
</style>
