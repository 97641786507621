import http from '@/utils/http'

export const get = id => http.get(`/user/item?id=${id}`)

export const list = params => http.get('/user/list', { params: params })

export const bindUser = params => http.post('/user/bind', params)

export const search = text => http.get(`/user/search?text=${text}`)

export const migrate = params => http.post('/user/migrate', params)

export const save = params => http.post('/user/save', params)

export const getPublicUsers = params => http.get('/user/public', { params: params })

export const unBindUser = params => http.post('/user/bind/cancel', params)
