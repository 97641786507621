import http from '@/utils/http'

export const getToken = () => http.get('/qiniu/token')

export const upload = (data) => http.post('/file/upload', data,
  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
)

export const uploadVideo = (data) => http.post('/file/upload/video', data,
  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
)

export const saveFileInfo = (data) => http.post('/file/save', data)
