export function isString (value) {
  return Object.prototype.toString.call(value) === '[object String]'
}

export function isArray (value) {
  return Object.prototype.toString.call(value) === '[object Array]'
}

export function isFunction (value) {
  return Object.prototype.toString.call(value) === '[object Function]'
}

export function underlineName (input) {
  return input.split(/(?=[A-Z])/).join('_').toLowerCase()
}

export function getTimeStr () {
  return (new Date()).getTime()
}

export function dataURLtoFile (dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const isAndroid = (ua) => {
  return ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
}

export const isIos = (ua) => {
  return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}
